
import { mapActions, mapGetters, mapMutations } from "vuex";
import offers from "@/apollo/queries/offer/offers";

export default {
  computed: {
    ...mapGetters({
      view: "filters/filters/view",
      offers: "filters/filters/offers",
      componentCatalog: "filters/filters/componentCatalog",
      loading: "filters/filters/loading"
    }),
    moreOffersData() {
      return this.offers;
    },
    skeleton() {
      return "skeleton-card-small";
    },
    catalog() {
      return this.view === "s"
        ? "catalog-item-minicard"
        : "catalog-item-minicard";
    }
  },
  mounted() {
    if (this.offers){
      this.setOffer(null);
    }

  },
  methods: {
    ...mapActions({
      request: "filters/filters/request"
    }),
    ...mapMutations({
      setCarPageLoaded: "catalog/catalog-cars/SET_CAR_PAGE_LOADED",
      setOffer: "catalog/catalog-cars/SET_OFFER"
    }),
    async filterRequest(assignVariables) {
      try {
        let response = await this.request({
          query: offers,
          variables: assignVariables
        });
        this.offers.data = [...this.offers.data, ...response.data.offers.data];
        this.offers.current_page = response.data.offers.current_page;
        await this.$store.commit("filters/filters/SET_OFFERS", this.offers);
      } catch (error) {
        return this.$nuxt.error({ statusCode: 404, message: "404" });
      }
    },
    moreOffers() {
      this.filterRequest({
        url: this.$route.path === "/best-moscow-autosalon"
            ? "/used"
            : "/used",
        page: this.offers ? Number(this.offers.current_page + 1) : 1,
        dateFormat: "j F Y года.",
        mark_slug_array: this.$stringToArray(this.$route.query.mark_slug_array),
        folder_slug_array: this.$stringToArray(
          this.$route.query.folder_slug_array
        ),
        generation_slug_array: this.$stringToArray(
          this.$route.query.generation_slug_array
        ),
        engine_type_id_array: this.$numberToArray(
          this.$route.query.engine_type_id_array
        ),
        gearbox_id_array: this.$numberToArray(
          this.$route.query.gearbox_id_array
        ),
        drive_type_id_array: this.$numberToArray(
          this.$route.query.drive_type_id_array
        ),
        body_type_id_array: this.$numberToArray(
          this.$route.query.body_type_id_array
        ),
        price_from: Number(this.$route.query.price_from),
        price_to: Number(this.$route.query.price_to),
        year_from: Number(this.$route.query.year_from),
        year_to: Number(this.$route.query.year_to),
        sort: this.$route.query.sort || this.sort,
        limit: 9
      });
    },
    universalScroll(mob, deck) {
      setTimeout(() => {
        if (this.$device.isMobile) {
          const target = document.body.getElementsByClassName(mob)[0];
          window.scrollBy({
            top: target.getBoundingClientRect().top - 60,
            left: 0,
            behavior: "smooth"
          });
        } else {
          const target = document.body.getElementsByClassName(deck)[0];
          window.scrollBy({
            top: target.getBoundingClientRect().top - 120,
            left: 0,
            behavior: "smooth"
          });
        }
      });
    }
  },
  watch: {
    "$route.query.page"(value) {
      setTimeout(() => {
        if (this.$device.isMobile) {
          const target = document.body.getElementsByClassName(
            "catalog__filter--new"
          )[0];
          window.scrollBy({
            top: target.getBoundingClientRect().top - 60,
            left: 0
          });
        } else {
          const target = document.body.getElementsByClassName(
            "catalog__filter--new"
          )[0];
          window.scrollBy({
            top: target.getBoundingClientRect().top - 120,
            left: 0
          });
        }
      }, 10);
    }
  }
};
